.Heading {
  position: relative;
  display: inline-block;
  .svg {
    top: 52%;
    left: -38%;
    position: absolute;
    pointer-events: none;
    fill: none;
    stroke-width: 1px;
    width: 100%;
    height: 1em;

    path {
      transition: stroke-dashoffset 0.3s cubic-bezier(0.7, 0, 0.3, 1);
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
    }
  }

  &:hover {
    svg path {
      stroke-dashoffset: 0;
    }
  }
}
