.WorkExperience {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'extra'
    'content'
    'image';
  grid-gap: 4rem 1rem;

  @media (min-width: 768px) {
    grid-template-columns: 30% 70%;
    grid-gap: 2rem 1rem;
    grid-template-areas:
      'title title'
      'extra content'
      'image content';
  }

  @media (min-width: 1250px) {
    grid-template-columns: 20% 1fr 1fr;
    grid-gap: 2rem 6rem;
    grid-template-areas:
      'title title title'
      'extra content image';
  }

  .title {
    grid-area: title;
  }

  .extra {
    grid-area: extra;
  }

  p a {
    @apply text-primary;
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size 0.3s cubic-bezier(0.2, 1, 0.8, 1);

    &:hover,
    &:focus {
      text-decoration: none;
      background-size: 100% 2px;
    }
  }

  .content {
    max-width: 64ch;
    grid-area: content;

    ul {
      @apply list-disc;
    }
  }

  .image {
    grid-area: image;
  }
}
