.DownloadButton {
  border-radius: 0.5rem;
  overflow: hidden;
  color: theme('colors.black');
  background-color: theme('colors.primary');
  position: relative;
  transition: color 0.4s cubic-bezier(0.3, 1, 0.8, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;

    background-color: theme('colors.button');
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  span {
    position: relative;
  }

  &:hover {
    color: theme('colors.white');

    &::before {
      transform: translate3d(100%, 0, 0);
    }
  }
}
